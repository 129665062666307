import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subscription } from 'rxjs';

import { AuthService } from './core/services/base/auth.service';
import { ConstantsService } from './core/services/base/constants.service';
import { LocalStorageManagementService } from './core/services/base/local-storage-management.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from './core/services/theme.service';
import { UtilsService } from './core/services/base/utils.service';

import { BackendConstant } from './shared/models/backend-constant.model';
import { Constants } from './core/constants/constants';
import { Customer, CustomerSetting } from './shared/models/customer.model';
import { User } from './shared/models/user.model';

import { VERSION } from '@env/version';

@Component({
    selector: 'rc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private languages!: BackendConstant[];
    private userSettingsByTag!: Record<string, any>;
    private userLoginSubscription!: Subscription;

    constructor(
        private authService: AuthService,
        private constantsService: ConstantsService,
        private localStorageService: LocalStorageManagementService,
        private themeService: ThemeService,
        private translateService: TranslateService,
        private utilsService: UtilsService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit(): void {
        this.applyUserSettings();
        this.listenForUserLogin();
        this.setupAppVersion();
    }

    applyUserSettings(): void {
        if (!this.authService.isTokenExpired()) {
            const user: User | null = this.localStorageService.getUserData();
            const customer: Customer | null =
                this.localStorageService.getSelectedCustomer();

            this.constantsService
                .getConstants()
                .subscribe((constants: Record<string, any>) => {
                    this.languages = constants[Constants.USER_LANGUAGES];
                    this.userSettingsByTag = this.utilsService.arrayToObject(
                        constants[Constants.CUSTOMER_SETTING_TYPES],
                        'tag'
                    );

                    if (user) {
                        this.setAppLanguage(user);
                    }

                    if (customer && customer.settings) {
                        this.updateAppTheme(customer.settings);
                    }
                });
        }
    }

    listenForUserLogin(): void {
        this.userLoginSubscription =
            this.authService.notifyUserLogin$.subscribe(() =>
                this.applyUserSettings()
            );
    }

    updateAppTheme(settings: CustomerSetting[]): void {
        const primaryColorSetting = settings.find(
            (setting: CustomerSetting) =>
                setting.type_id ===
                this.userSettingsByTag[Constants.MAIN_COLOR].id
        );

        if (primaryColorSetting) {
            this.themeService.updateAppTheme(primaryColorSetting.value);
        }
    }

    setAppLanguage(user: User): void {
        const userLanguage = this.languages.find(
            (language: BackendConstant) => language.id === user.language_id
        );

        if (userLanguage) {
            this.translateService.use(userLanguage.tag.toLowerCase());
        }
    }

    //
    // ─── VERSIONS - METHODS ─────────────────────────────────────────────────────────
    //

    setupAppVersion(): void {
        const version = this.document.createElement('script');
        version.type = 'text/javascript';
        version.text = `window.appVersion = function() { return '${VERSION.version}' + ' - ' + '${VERSION.hash}' }`;
        this.document.body.appendChild(version);
    }

    ngOnDestroy(): void {
        this.userLoginSubscription.unsubscribe();
    }
}
