import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LoggedInGuard } from './core/guards/logged-in.guard';

import { LayoutAuthComponent } from './shared/layouts/layout-auth/layout-auth.component';
import { LayoutPagesComponent } from './shared/layouts/layout-pages/layout-pages.component';

const routes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    {
        path: '',
        component: LayoutAuthComponent,
        children: [
            {
                path: 'auth',
                canActivateChild: [LoggedInGuard],
                loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)
            },
            {
                path: 'customer-selection',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./features/customer-selector/customer-selector.module').then(m => m.CustomerSelectorModule)
            }
        ]
    },
    {
        path: '',
        component: LayoutPagesComponent,
        children: [
            {
                path: 'dashboard',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'conserva',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./features/conserva/conserva.module').then(m => m.ConservaModule)
            },
            {
                path: 'settings',
                canActivateChild: [AuthGuard],
                loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule)
            }
        ]
    },
    { path: '**', redirectTo: 'dashboard' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
