import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let apiRequest;

        if (request.url.includes('i18n') || request.url.includes('assets/docs/')) {
            apiRequest = request;
        } else {
            apiRequest = request.clone({
                url: `${environment.apiUrl}/${request.url}`
            });
        }

        return next.handle(apiRequest);
    }
}
